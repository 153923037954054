import LocalizedStrings from 'react-localization';
 
export const projectsLang = new LocalizedStrings({
	'en-us':{
		// overview
		"course": "COURSE",
		"overview": "Overview",
		"outcome": "Learning Outcome",
		"curriculum": "Curriculum",
		"complete": "COMPLETE!",
		"enroll_course": "Enrol Course", 
		"login_to_enroll": "Login to Enrol",
		"teaching_hours": "Teaching Hours",
		"duration": "Duration",
		"review_assessment": "Review & Assessment",
		"included": "Included",
		"excluded": "Excluded",
		"related_projects": "RELATED PROJECTS",
		"oh_dear": "Oh dear, the course is locked...",
		"contact_your_teacher": "Contact your teacher to view the content.",

		// lessons
		"next_step": "NEXT",
		"previous_step": "PREVIOUS",
		"back_to_course": "Back to course",
		"extras": "EXTRAS",
		"learn": "Learn",
		"try": "Try",
		"review": "Review",
		"inspect": "Inspect",
		"assessment": "Assessment",
		"explore": "Explore",
		"challenge": "Challenge",
		"learn_process": "Study the situation and principles with interactive slideshows and offline teaching materials.",
		"try_process": "Try out the example to learn the features and experience of the program.",
		"inspect_process": "Go through the key programming processes step by step to learn the principals behind.",
		"review_process": "Review what you have learned and evaulate learning outcome with short quizzes.",
		"explore_process": "Handpicked readings and videos that you would wish to deep dive into the topic.",
		"challenge_process": "Advanced programming challenge to test your knowledge and allow you to code your project.",

		// learn
		"page_of": "{0} of {1}",
		"learn_description": "Learn the basics of neural network.",

		// inspect
		"next_flow": "Correct! Move on to the next flow.",
		"inspect_description": "Make {0} works by completing tasks in all modules. Some of the modules are already done for you.",
		"tab_overview": "Overview",
		"no_error": "No errors found",
		"have_error": "Errors found",
		"reset": "RESET",
		"validate": "VALIDATE",

		// assessment
		"assessment_results": "Assessment Results",
		"quiz": "Quiz",
		"summary": "Summary",
		"question_no": "Question {0}",
		"total_questions": "Test your knowledge with the quiz. There are {0} questions in total.",
		"online_learning": "Online Learning",
		"congratulations": "Congratulations!",
		"completed_the_course": "You’ve successfully completed the course! Wanna deep dive?",
		"view_result": "View Result",
		"check_answer": "Check Answer",
		"you_are_correct": "Yes, you are right!",
		"you_are_wrong": "Nope, the correct answer is: {0}",
		"next_question": "Next",
		"select_the_right_answer": "Select the right answer:",
		"take_assessment_again": "Take Assessment Again",
		"review_description": "Let’s summarize what you have learnt.",
		"quiz_result": "Result",
		"time_spent": "Time spent",
		"result_value": "{0} of {1}",
		"have_learnt": "What you have learnt",

		// explore
		"explore_description": "More learning materials and resources for you to explore.",

		// challenge
		"challenge_info": "Take on the programming challenge by applying what you have learned.",
		"easy": "Easy",
		"intermediate": "Intermediate",
		"hard": "Hard",
		"start": "Start",

		// code editor
		"code_editor": "Code Editor",
		"add": "Add",
		"execute_command": "Shift + Enter",
		"execute_hints": "Hints: Type {0} to execute commands",
		"editor": "Editor",
	},
});

export default { projectsLang };
