import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import {
  Z_INDEX_MAIN,
} from '../../constants/dom.constant';
import {
  ROUTE_PROJECTS,
  ROUTE_REPORT,
  ROUTE_OVERVIEW,
  ROUTE_COURSE_PREFIX,
  ROUTE_COURSE_DEPTH,
  ROUTE_LEARN,
  ROUTE_TRY,
  ROUTE_INSPECT,
  ROUTE_REVIEW,
  ROUTE_EXPLORE,
  ROUTE_CHALLENGE,
  ROUTE_QUIZ,
  ROUTE_SUMMARY,
  ROUTE_LOGIN,
  ROUTE_HOME,
} from '../../constants/app.constant';
import { isAuthorized } from '../../reducers/rules/auth.rules';
import { ContextStore } from '../../store/ContextStore';
import MainHeader from './Common/MainHeader';
import MainFooter from './Common/MainFooter';
import Lightbox from './Common/Lightbox';
import WelcomeModal from './Common/WelcomeModal';
import ProjectHeader from './Common/ProjectHeader';
import MainSider from './Common/MainSider';
import OverviewHeader from './Overview/OverviewHeader';
import ProjectsView from './Projects';
import ReportView from './Report';
import OverviewView from './Overview';
import LearnView from './Lessons/Learn';
import TryView from './Lessons/Try';
import InspectView from './Lessons/Inspect';
import ReviewView from './Lessons/Review';
import ExploreView from './Lessons/Explore';
import ChallengeView from './Lessons/Challenge';
import AssessmentView from './Lessons/Assessment';
import SummaryView from './Lessons/Assessment/Summary';
import LoginView from './Login';

const { Content } = Layout;
const coursePages = [ROUTE_LEARN, ROUTE_TRY, ROUTE_INSPECT, ROUTE_REVIEW, ROUTE_EXPLORE, ROUTE_CHALLENGE, ROUTE_QUIZ, ROUTE_SUMMARY];
const hideFooter = [ROUTE_INSPECT, ROUTE_QUIZ];

function Auth(props) {

  const { auth } = React.useContext(ContextStore);

  useEffect(() => {
    // <----- redirect to login page
    if(!isAuthorized(auth))
      if(getPathFromUrl(coursePages))
        props.history.push(ROUTE_LOGIN);
  });

  function getPathFromUrl(routes, exclude) {
    const pathname = props.history.location.pathname;
    const path = pathname.indexOf(ROUTE_COURSE_PREFIX)===0
                  ? ROUTE_COURSE_PREFIX+'/:name/'+pathname.split('/')[ROUTE_COURSE_DEPTH]
                  : '/:name/'+pathname.split('/')[2];
    if(exclude){
      return !routes.includes(path);
    }
    return routes.includes(path);
  }

  return (
  	<Layout style={{minHeight: `100vh`}}>
      <Lightbox />
      <WelcomeModal />
      {
        getPathFromUrl(coursePages) &&
        <MainSider history={props.history} />
      }
      <Layout>
        {
          getPathFromUrl(coursePages)
          ? <ProjectHeader history={props.history} />
          : <MainHeader history={props.history} pathname={props.history.location.pathname} />
        }
        <Content style={{marginTop:`64px`, zIndex: Z_INDEX_MAIN }}>
          {
            getPathFromUrl([ROUTE_OVERVIEW]) &&
            <OverviewHeader />
          }
          <div style={{padding: `24px 32px`}}>
            <div className="container">
              <Route exact path={ROUTE_REPORT} component={ReportView} />
              <Route exact path={ROUTE_OVERVIEW} component={OverviewView} />
            </div>
            { isAuthorized(auth) && 
            <div className="course-container">
              <Route exact path={ROUTE_LEARN} component={LearnView} />
              <Route exact path={ROUTE_REVIEW} component={ReviewView} />
              <Route exact path={ROUTE_EXPLORE} component={ExploreView} />
              <Route exact path={ROUTE_QUIZ} component={AssessmentView} />
              <Route exact path={ROUTE_SUMMARY} component={SummaryView} />
              <Route exact path={ROUTE_CHALLENGE} component={ChallengeView} />
            </div>
            }
            <Route exact path={ROUTE_HOME} component={ProjectsView} />
            <Route exact path={ROUTE_PROJECTS} component={ProjectsView} />
            <Route exact path={ROUTE_LOGIN} component={LoginView} />
            <Route exact path={ROUTE_INSPECT} component={InspectView} />
            <Route exact path={ROUTE_TRY} component={TryView} />
          </div>
        </Content>
        {
          getPathFromUrl(hideFooter, true) && props.history.location.pathname!==ROUTE_LOGIN &&
          <MainFooter />
        }
      </Layout>
    </Layout>
  );
}
export default Auth;
