// RL environment related
export const maxGameSpeed = 10;
export const carBrakingMode = 0;		// 0 = Constant, 1 = Variable


// RL Training / Evaluation Related 
export const rl_mode = 1;	// 0 = tensorflowjs, 1 = Q-table
export const stateInputNo = 2;
export const recommendedNoOfTrainToDo = -1;	//30;
export const noOfEvaluationTrial = 5;
export const actionPerSecond = 15;
export const maxInitialVelocity = 40;
export const minInitialVelocity = 30;
export const maxInitialDistance = 50;
export const minInitialDistance = 30;
export const episodeTerminationDistance = -20;
export const availableBrakingForceFactorArray = [1, 2, 3, 4];
export const availableBrakingForceFactorWeightArray = [1, 1, 1, 1];

// RL Reward Related 
export const largestReward = 100;
export const smallestReward = -100;

const rewardItemDetailsDummyValue = -999999;
export const rewardItemArray = [
	{
		isFinalSituation: true,

		distUpperLimit: rewardItemDetailsDummyValue,
		distLowerLimit: 20,
		hasUpperLimit: false,
		hasLowerLimit: true,

		defaultReward: 5,
		reward: 5,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 20,
		distLowerLimit: 10,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: 10,
		reward: 10,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 10,
		distLowerLimit: 2,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: 30,
		reward: 30,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 2,
		distLowerLimit: 0,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: 60,
		reward: 60,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 0,
		distLowerLimit: -10,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: -10,
		reward: -10,
	},
	{
		isFinalSituation: true,

		distUpperLimit: -10,
		distLowerLimit: rewardItemDetailsDummyValue,
		hasUpperLimit: true,
		hasLowerLimit: false,

		defaultReward: -30,
		reward: -30,
	},
]
